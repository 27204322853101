@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&amp;lang=en");
/*
 *
 *   INSPINIA Landing Page - Responsive Admin Theme
 *
*/
/* GLOBAL STYLES
-------------------------------------------------- */
body {
  color: #676a6c;
  font-family: 'Open Sans', helvetica, arial, sans-serif;
}
span.navy {
  color: #008ba3;
}
p.text-color {
  color: #676a6c;
}
a.navy-link {
  color: #1ab394;
  text-decoration: none;
}
a.navy-link:hover {
  color: #17a185;
}
section p {
  color: #aeaeae;
  font-size: 13px;
}
address {
  font-size: 13px;
}
h1 {
  margin-top: 10px;
  font-size: 30px;
  font-weight: 200;
}
.navy-line {
  width: 60px;
  height: 1px;
  margin: 60px auto 0;
  border-bottom: 2px solid #008ba3;
}
.navy-bg {
  background-color: #1ab394;
  color: #ffffff;
}
/* CUSTOMIZE THE NAVBAR
-------------------------------------------------- */
.navbar-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
}
/* Flip around the padding for proper display in narrow viewports */
.navbar-wrapper > .container {
  padding-right: 0;
  padding-left: 0;
}
.navbar-wrapper .navbar {
  padding-right: 15px;
  padding-left: 15px;
}
.navbar-default.navbar-scroll {
  background-color: #fff;
  border-color: #fff;
  padding: 15px 0;
}
.navbar-default {
  background-color: transparent;
  border-color: transparent;
  transition: all 0.3s ease-in-out 0s;
}
.navbar-default .nav li a {
  color: #fff;
  font-family: 'Open Sans', helvetica, arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.navbar-nav > li > a {
  padding-top: 25px;
  border-top: 6px solid transparent;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover {
  background: transparent;
  color: #fff;
  border-top: 6px solid #1ab394;
}
.navbar-default .navbar-nav > li > a:hover {
  color: #008ba3;
}
.navbar-default .navbar-nav > .active > a:focus {
  background: transparent;
  color: #fff;
}
.navbar-default.navbar-scroll .navbar-nav > .active > a:focus {
  background: transparent;
  color: inherit;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  background: #008ba3;
  color: #fff;
}
.navbar-default .navbar-brand {
  color: #fff;
  height: auto;
  display: block;
  font-size: 14px;
  background: #008ba3;
  padding: 15px 20px 15px 20px;
  border-radius: 0 0px 5px 5px;
  font-weight: 700;
  transition: all 0.3s ease-in-out 0s;
}
.navbar-scroll.navbar-default .nav li a {
  color: #676a6c;
}
.navbar-scroll.navbar-default .nav li a:hover {
  color: #1ab394;
}
.navbar-wrapper .navbar.navbar-scroll {
  padding-top: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #e7eaec;
  border-radius: 0;
}
.nav > li > a {
  padding: 25px 10px 15px 10px;
}
.navbar-scroll .navbar-nav > li > a {
  padding: 20px 10px;
}
.navbar.navbar-scroll .navbar-brand {
  margin-top: 15px;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;
  height: auto;
}
/* BACKGROUNDS SLIDER
-------------------------------------------------- */
.header-back {
  height: 470px;
  width: 100%;
}
.header-back.one {
  background: url('../img/header_one.jpg') 50% 0 no-repeat;
}
.header-back.two {
  background: url('../img/header_two.jpg') 50% 0 no-repeat;
}
/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
/* Carousel base class */
.carousel {
  height: 470px;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
}
/* Declare heights because of positioning of img element */
.carousel .item {
  height: 470px;
  background-color: #777;
}
.carousel-inner > .item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 470px;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
.carousel-control.left,
.carousel-control.right {
  background: none;
}
.carousel-control {
  width: 6%;
}
.carousel-inner .container {
  position: relative;
}
.carousel-inner {
  overflow: visible;
}
.carousel-caption {
  position: absolute;
  top: 100px;
  left: 0;
  bottom: auto;
  right: auto;
  text-align: left;
}
.carousel-caption {
  position: absolute;
  top: 100px;
  left: 0;
  bottom: auto;
  right: auto;
  text-align: left;
}
.carousel-caption.blank {
  top: 140px;
}
.carousel-image {
  position: absolute;
  right: 10px;
  top: 150px;
}
.carousel-indicators {
  padding-right: 60px;
}
.carousel-caption h1 {
  font-weight: 700;
  font-size: 38px;
  text-transform: uppercase;
  text-shadow: none;
  letter-spacing: -1.5px;
}
.carousel-caption p {
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: none;
}
.caption-link {
  color: #fff;
  margin-left: 10px;
  text-transform: capitalize;
  font-weight: 400;
}
.caption-link:hover {
  text-decoration: none;
  color: inherit;
}
/* Sections
------------------------- */
.services {
  padding-top: 60px;
}
.services h2 {
  font-size: 20px;
  letter-spacing: -1px;
  font-weight: 600;
  text-transform: uppercase;
}
.features-block {
  margin-top: 40px;
}
.features-text {
  margin-top: 40px;
}
.features small {
  color: #1ab394;
}
.features h2 {
  font-size: 18px;
  margin-top: 5px;
}
.features-text-alone {
  margin: 40px 0;
}
.features-text-alone h1 {
  font-weight: 200;
}
.features-icon {
  color: #1ab394;
  font-size: 40px;
}
.navy-section {
  margin-top: 60px;
  background: #1ab394;
  color: #fff;
  padding: 20px 0;
}
.gray-section {
  background: #f4f4f4;
  margin-top: 60px;
}
.team-member {
  text-align: center;
}
.team-member img {
  margin: auto;
}
.social-icon a {
  background: #1ab394;
  color: #fff;
  padding: 4px 8px;
  height: 28px;
  width: 28px;
  display: block;
  border-radius: 50px;
}
.social-icon a:hover {
  background: #17a185;
}
.img-small {
  height: 88px;
  width: 88px;
}
.pricing-plan {
  margin: 20px 30px 0 30px;
  border-radius: 4px;
}
.pricing-plan.selected {
  transform: scale(1.1);
  background: #f4f4f4 ;
}
.pricing-plan li {
  padding: 10px 16px;
  border-top: 1px solid #e7eaec;
  text-align: center;
  color: #aeaeae;
}
.pricing-plan .pricing-price span {
  font-weight: 700;
  color: #1ab394;
}
li.pricing-desc {
  font-size: 13px;
  border-top: none;
  padding: 20px 16px ;
}
li.pricing-title {
  background: #1ab394;
  color: #fff;
  padding: 10px;
  border-radius: 4px 4px 0 0;
  font-size: 22px;
  font-weight: 600;
}
.testimonials {
  padding-top: 80px;
  padding-bottom: 90px;
  background-color: #1ab394;
  background-image: url('../img/avatar_all.png');
}
.big-icon {
  font-size: 56px;
}
.features .big-icon {
  color: #008ba3;
}
.contact {
  background-image: url('../img/word_map.png');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-top: 60px;
}
/* Buttons - only primary custom button
------------------------- */
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}
.btn-primary {
  background-color: #008ba3;
  border-color: #008ba3;
  color: #fff;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: darken(#008ba3, 5%);
  border-color: darken(#008ba3, 5%);
  color: #FFFFFF;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary.active[disabled],
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: darken(#008ba3, 5%);
  border-color: darken(#008ba3, 5%);
}
/* RESPONSIVE CSS
-------------------------------------------------- */
@media (min-width: 768px) {
  /* Navbar positioning foo */
  .navbar-wrapper {
    margin-top: 0px;
  }
  .navbar-wrapper .container {
    padding-right: 15px;
    padding-left: 15px;
  }
  .navbar-wrapper .navbar {
    padding-right: 0;
    padding-left: 0;
  }
  /* The navbar becomes detached from the top, so we round the corners */
  .navbar-wrapper .navbar {
    border-radius: 4px;
  }
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.4;
  }
  .featurette-heading {
    font-size: 50px;
  }
}
@media (max-width: 992px) {
  .carousel-image {
    display: none;
  }
}
@media (max-width: 768px) {
  .carousel-caption,
  .carousel-caption.blank {
    left: 5%;
    top: 80px;
  }
  .carousel-caption h1 {
    font-size: 28px;
  }
  .navbar.navbar-scroll .navbar-brand {
    margin-top: 6px;
  }
  .navbar-default {
    background-color: #fff;
    border-color: #fff;
    padding: 15px 0;
  }
  .navbar-default .navbar-nav > .active > a:focus {
    background: transparent;
    color: inherit;
  }
  .navbar-default .nav li a {
    color: #676a6c;
  }
  .navbar-default .nav li a:hover {
    color: #1ab394;
  }
  .navbar-wrapper .navbar {
    padding-top: 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #e7eaec;
    border-radius: 0;
  }
  .nav > li > a {
    padding: 25px 10px 15px 10px;
  }
  .navbar-nav > li > a {
    padding: 20px 10px;
  }
  .navbar .navbar-brand {
    margin-top: 6px;
    border-radius: 5px;
    font-size: 12px;
    padding: 10px;
    height: auto;
  }
  .navbar-wrapper .navbar {
    padding-left: 15px;
    padding-right: 5px;
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover {
    color: inherit;
  }
  .carousel-control {
    display: none;
  }
}
@media (min-width: 992px) {
  .featurette-heading {
    margin-top: 120px;
  }
}
/* MARGINS & PADDINGS
-------------------------------------------------- */
.p-xxs {
  padding: 5px;
}
.p-xs {
  padding: 10px;
}
.p-sm {
  padding: 15px;
}
.p-m {
  padding: 20px;
}
.p-md {
  padding: 25px;
}
.p-lg {
  padding: 30px;
}
.p-xl {
  padding: 40px;
}
.m-xxs {
  margin: 2px 4px;
}
.m-xs {
  margin: 5px;
}
.m-sm {
  margin: 10px;
}
.m {
  margin: 15px;
}
.m-md {
  margin: 20px;
}
.m-lg {
  margin: 30px;
}
.m-xl {
  margin: 50px;
}
.m-n {
  margin: 0 !important;
}
.m-l-none {
  margin-left: 0;
}
.m-l-xs {
  margin-left: 5px;
}
.m-l-sm {
  margin-left: 10px;
}
.m-l {
  margin-left: 15px;
}
.m-l-md {
  margin-left: 20px;
}
.m-l-lg {
  margin-left: 30px;
}
.m-l-xl {
  margin-left: 40px;
}
.m-l-n-xxs {
  margin-left: -1px;
}
.m-l-n-xs {
  margin-left: -5px;
}
.m-l-n-sm {
  margin-left: -10px;
}
.m-l-n {
  margin-left: -15px;
}
.m-l-n-md {
  margin-left: -20px;
}
.m-l-n-lg {
  margin-left: -30px;
}
.m-l-n-xl {
  margin-left: -40px;
}
.m-t-none {
  margin-top: 0;
}
.m-t-xxs {
  margin-top: 1px;
}
.m-t-xs {
  margin-top: 5px;
}
.m-t-sm {
  margin-top: 10px;
}
.m-t {
  margin-top: 15px;
}
.m-t-md {
  margin-top: 20px;
}
.m-t-lg {
  margin-top: 30px;
}
.m-t-xl {
  margin-top: 40px;
}
.m-t-n-xxs {
  margin-top: -1px;
}
.m-t-n-xs {
  margin-top: -5px;
}
.m-t-n-sm {
  margin-top: -10px;
}
.m-t-n {
  margin-top: -15px;
}
.m-t-n-md {
  margin-top: -20px;
}
.m-t-n-lg {
  margin-top: -30px;
}
.m-t-n-xl {
  margin-top: -40px;
}
.m-r-none {
  margin-right: 0;
}
.m-r-xxs {
  margin-right: 1px;
}
.m-r-xs {
  margin-right: 5px;
}
.m-r-sm {
  margin-right: 10px;
}
.m-r {
  margin-right: 15px;
}
.m-r-md {
  margin-right: 20px;
}
.m-r-lg {
  margin-right: 30px;
}
.m-r-xl {
  margin-right: 40px;
}
.m-r-n-xxs {
  margin-right: -1px;
}
.m-r-n-xs {
  margin-right: -5px;
}
.m-r-n-sm {
  margin-right: -10px;
}
.m-r-n {
  margin-right: -15px;
}
.m-r-n-md {
  margin-right: -20px;
}
.m-r-n-lg {
  margin-right: -30px;
}
.m-r-n-xl {
  margin-right: -40px;
}
.m-b-none {
  margin-bottom: 0;
}
.m-b-xxs {
  margin-bottom: 1px;
}
.m-b-xs {
  margin-bottom: 5px;
}
.m-b-sm {
  margin-bottom: 10px;
}
.m-b {
  margin-bottom: 15px;
}
.m-b-md {
  margin-bottom: 20px;
}
.m-b-lg {
  margin-bottom: 30px;
}
.m-b-xl {
  margin-bottom: 40px;
}
.m-b-n-xxs {
  margin-bottom: -1px;
}
.m-b-n-xs {
  margin-bottom: -5px;
}
.m-b-n-sm {
  margin-bottom: -10px;
}
.m-b-n {
  margin-bottom: -15px;
}
.m-b-n-md {
  margin-bottom: -20px;
}
.m-b-n-lg {
  margin-bottom: -30px;
}
.m-b-n-xl {
  margin-bottom: -40px;
}
.space-15 {
  margin: 15px 0;
}
.space-20 {
  margin: 20px 0;
}
.space-25 {
  margin: 25px 0;
}
.space-30 {
  margin: 30px 0;
}
/* PACE PLUGIN
-------------------------------------------------- */
.pace .pace-progress {
  background: #fff;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 2px;
  transition: width 1s;
}
.pace-inactive {
  display: none;
}
section.timeline {
  padding-bottom: 30px;
}
section.comments {
  padding-bottom: 80px;
}
.comments-avatar {
  margin-top: 25px;
  margin-left: 22px;
}
.comments-avatar .commens-name {
  font-weight: 600;
  font-size: 14px;
}
.comments-avatar img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 10px;
}
.bubble {
  position: relative;
  height: 120px;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 10px;
  font-style: italic;
  font-size: 14px;
}
.bubble:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 15px 14px 0;
  border-color: #FFFFFF transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -15px;
  left: 30px;
}
/* New Timeline style */
.vertical-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.vertical-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}
#vertical-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
}
#vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #f1f1f1;
}
.vertical-timeline-content .btn {
  float: right;
}
#vertical-timeline.light-timeline:before {
  background: #e7eaec;
}
.dark-timeline .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent ;
}
.dark-timeline.center-orientation .vertical-timeline-content:before {
  border-color: transparent  transparent transparent #f5f5f5;
}
.dark-timeline .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before,
.dark-timeline.center-orientation .vertical-timeline-block:nth-child(2n) .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent;
}
.dark-timeline .vertical-timeline-content,
.dark-timeline.center-orientation .vertical-timeline-content {
  background: #f5f5f5;
}
@media only screen and (min-width: 1170px) {
  #vertical-timeline.center-orientation {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #vertical-timeline.center-orientation:before {
    left: 50%;
    margin-left: -2px;
  }
}
@media only screen and (max-width: 1170px) {
  .center-orientation.dark-timeline .vertical-timeline-content:before {
    border-color: transparent #f5f5f5 transparent transparent;
  }
}
.vertical-timeline-block {
  position: relative;
  margin: 2em 0;
}
.vertical-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-block:first-child {
  margin-top: 0;
}
.vertical-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-block {
    margin: 4em 0;
  }
  .center-orientation .vertical-timeline-block:first-child {
    margin-top: 0;
  }
  .center-orientation .vertical-timeline-block:last-child {
    margin-bottom: 0;
  }
}
.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 3px solid #f1f1f1;
  text-align: center;
}
.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -9px;
}
@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-icon {
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    font-size: 19px;
  }
  .center-orientation .vertical-timeline-icon i {
    margin-left: -12px;
    margin-top: -10px;
  }
  .center-orientation .cssanimations .vertical-timeline-icon.is-hidden {
    visibility: hidden;
  }
}
.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
}
.vertical-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-content h2 {
  font-weight: 400;
  margin-top: 4px;
}
.vertical-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}
.vertical-timeline-content .vertical-date {
  float: left;
  font-weight: 500;
}
.vertical-date small {
  color: #1ab394;
  font-weight: 400;
}
.vertical-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}
@media only screen and (min-width: 768px) {
  .vertical-timeline-content h2 {
    font-size: 18px;
  }
  .vertical-timeline-content p {
    font-size: 13px;
  }
}
@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .center-orientation .vertical-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }
  .center-orientation .vertical-timeline-content .btn {
    float: left;
  }
  .center-orientation .vertical-timeline-content .vertical-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 2px;
    font-size: 14px;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content {
    float: right;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .btn {
    float: right;
  }
  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .vertical-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .center-orientation .cssanimations .vertical-timeline-content.is-hidden {
    visibility: hidden;
  }
}
